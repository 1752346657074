import React from 'react';
import './index.scss';
import PoliceImg from '../../img/police.png';

const Footer: React.FC = () => {
  return (
    <div className='footer'>
      {/* <div className="footer_info">
        <p>
          京东安联财产保险有限公司2021年第4季度的综合偿付能力充足率为200.4%，达到监管要求且2021年3季度风险综合评级（分类监管）结果为B类。详情可登录&nbsp;
          <a
            href="https://www.jdallianz.com/public-info/public-info.html#power"
            target="_blank"
            rel="noreferrer"
          >
            https://www.jdallianz.com/public-info/public-info.html#power
          </a>
          &nbsp;查询。
        
          As of Q4 2021, the solvency ratio of Allianz JingDong General
          Insurance Company Ltd. is 200.4%, higher than regulator's requirement.
          In addition, the integrated risk rating of 2021 Q3 is level B. More
          information is available on our website&nbsp;  
          <a
            href="https://www.jdallianz.com/public-info/public-info.html#power"
            target="_blank"
            rel="noreferrer"
          >
             https://www.jdallianz.com/public-info/public-info.html#power
          </a>
        </p>
      </div> */}
      <div className=' footer_last'>
        <div className='copyRight'>
          <div>Copyright © 2019 京东安联财产保险有限公司 版权所有 </div>
          <div>
            <a
              href='https://beian.miit.gov.cn/'
              target='_blank'
              rel='noreferrer'
            >
              粤ICP备07058166号-3
            </a>
          </div>
          <div className='badge'>
            <img src={PoliceImg} alt='' />
          </div>
          <div>
            <a
              href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602013086'
              target='_blank'
              rel='noreferrer'
            >
              粤公网安备 44010602013086号
            </a>
          </div>
        </div>
        <div>
          <span>
            <a
              href='https://img03.az.jd.com/ins-user-m/develop/html/private.html'
              target='_blank'
              rel='noreferrer'
            >
              隐私政策
            </a>
          </span>
          &nbsp;&nbsp;| &nbsp;&nbsp;
          <span>
            <a
              href='https://img03.az.jd.com/ins-user-m/develop/html/registration.html'
              target='_blank'
              rel='noreferrer'
            >
              注册协议
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
