export const data1 = [
  // '我司京东安联臻爱无限医疗保险2021版保险产品提供恶性肿瘤特药服务健康管理服务内容，合作机构为广西京东拓先电子商务有限公司，提供国际二次诊疗健康服务，合作机构为沃迪康(北京)医院管理有限公司；提供在线问诊及药品优惠券健康管理服务内容，合作机构为北京京东健康有限公司，100种重疾住院垫付及绿通服务，合作机构为上海商涌科技有限公司。',

  // '我司京东安联臻爱无限2021百万医疗保险（糖尿病版）产品提供100种重疾住院垫付及绿通服务，合作机构为上海商涌科技有限公司；慢病管理服务，合作机构为安睦来智慧健康科技（上海）有限公司；恶性肿瘤特药服务，服务提供商为上海镁信健康科技有限公司。',

  // '我司京东安联臻爱百万医疗保险计划（甲状腺病特别版）产品提供100种重疾住院垫付及绿通服务，合作机构为上海商涌科技有限公司。',

  // '我司京东安联超医保百万医疗险产品提供恶性肿瘤院外特药服务，合作机构为广西京东拓先电子商务有限公司；京东安联药品优惠券和互联网医院服务，合作机构为北京京东健康有限公司；沃迪康国际二次诊疗，合作机构为沃迪康（北京）医院管理有限公司。',

  // '我司京东安联全民医保·互联网甄选版/甄选版提供100种重疾住院绿通服务及垫付服务，合作机构为上海商涌科技有限公司、恶性肿瘤院外特药服务手册，合作机构为广西京东拓先电子商务有限公司。',

  // '我司惠民保(普惠型百万医疗保险)提供恶性肿瘤特药服务、住院费用垫付服务、日常健管服务（7*24 小时线上健康咨询、肿瘤早筛问卷、药品咨询指导、慈善赠药申请指导、临床试验申请服务、药品商城优惠购、慢病日常监测服务、术后护理服务、失能失智风险评估），服务提供商为上海商涌科技有限公司。',

  // '我司京东保险百万医疗组合险2021版提供100种重疾住院绿通及垫付服务，合作机构为上海商涌科技有限公司、恶性肿瘤院外特药服务，合作机构为广西京东拓先电子商务有限公司；沃迪康国际二次诊疗服务，合作机构为沃迪康（北京）医院管理有限公司；京东安联药品优惠券和互联网医院权益服务，合作机构为北京京东健康有限公司。',

  // '我司民惠保百万医疗产品提供恶性肿瘤特药服务、援助用药申请指导服务、药品咨询服务、海南博鳌乐城就医服务、临床试验申请服务、重大疾病早筛服务、慢病折扣卡、恶性肿瘤多学科会诊。服务提供商为上海镁信健康科技有限公司。',

  // '我司民惠保PLUS百万医疗险产品提供100种重疾住院绿通及垫付服务、恶性肿瘤院外特药服务。合作机构为上海镁信健康科技有限公司。',

  // '我司成长优享-未成年人门急诊保险产品提供预约安排疫苗、儿童健康讲座、健康文章推送、公立医疗预约、儿科疾病线上问诊、送药上门健康管理服务，合作机构为上海风时健康管理咨询有限公司。',

  // '我司成长优享-私立儿童齿科保险产品提供建立专属口腔档案、儿童刷牙指导及口腔护理指导、儿童齿科线上问诊健康管理服务，合作机构为上海风时健康管理咨询有限公司。',

  // '我司成长优享-私立儿童眼视力保险产品提供建立屈光发育档案健康管理服务，合作机构为上海风时健康管理咨询有限公司。',

  // '我司京东安联臻心医疗保险产品提供境外医疗领航健康管理服务，包含全球顶尖医疗机构二次诊疗意见及境外就医安排，合作机构为沃迪康(北京)医院管理有限公司。',

  // '我司京东安联臻爱无限医疗保险2020版保险产品提供恶性肿瘤特药服务健康管理服务内容，合作机构为上海镁信健康科技有限公司。',

  // '我司安联康睿寰球医疗保险提供国际医学专家意见报告、海外诊疗推荐、电话医生健康管理服务内容，合作机构为易医健康管理咨询（上海）有限公司。',

  // '我司京东安联蚂蚁保保障计划(2021版)保险提供京东安联药品优惠券和互联网医院服务，合作机构为北京京东健康有限公司。',

  // '我司京东安联乐享人生保险产品提供12小时电话医生服务服务，合作机构为安援救援管理服务（北京）有限公司。',

  // '我司家医保·家庭百万医疗险提供家庭医生服务，合作机构为北京京东健康有限公司；重大疾病住院绿通及垫付服务，合作机构为上海商涌科技有限公司。',

  // '我司京东安联互联网门诊险提供视频问诊及药品服务，合作机构为北京京东健康有限公司。',

  // '我司安享欣悦及安享商祺高端医疗保险产品提供网络类医院就诊预约和安排服务；第二诊疗意见安排服务；医学陪诊服务；药品直送服务；合作机构为万欣和（上海）企业服务有限公司。',

  // '我司京东安联住院宝产品提供京东安联药品优惠券和互联网医院服务，合作机构为北京京东健康有限公司。',

  // '我司京东安联住院宝（吉祥版）产品提供24小时图文问诊服务及就医陪诊服务，合作机构为北京京东健康有限公司。',

  // '我司对于北京京东世纪贸易有限公司员福业务提供体检及绿通服务，合作机构为欣和（上海）企业服务有限公司、启泰元康健康管理有限公司。',

  // '我司对华为员工境外旅行保险业务提供医疗救援服务；电话医疗咨询；医疗机构介绍和建议服务；紧急电话翻译服务；合作机构为安联世合国际救援服务（北京）有限公司。',
  "京东安联-成长优享2024系列产品提供儿科疾病电话问诊服务、儿科线上图文问诊服务、就医分诊服务、协助预约安排疫苗服务、健康科普服务、公立医院特需部驻点服务、心理健康咨询服务，合作机构为上海风时健康技术有限公司。",
  "京东安联成长优享家庭计划高端医疗保险（2024版）产品提供健康档案服务、家庭医生服务、肿瘤早筛服务（乳腺、卵巢肿瘤家族风险筛查，肺、肝、肠、食管、胰腺、卵巢多癌种早期检测）、恶性肿瘤精准治疗检测服务（包含肿瘤基因检测，恶性肿瘤分子残留标志物检测）、恶性肿瘤及罕见病院外特定药品直付（包含CAR-T）服务、门诊绿通服务（限首次重疾）、住院绿通服务（限首次重疾）、公立医院住院陪诊和垫付服务、重疾二次诊疗服务、重疾多学科会诊MDT服务、术后上门护理服务、国际二诊服务（仅限恶性肿瘤重度）、少儿先进医疗定制化增值服务（仅限0-14周岁儿童）、海外先进治疗首次就医陪诊服务、海外先进治疗医疗费用直付服务，合作机构为上海风时健康技术有限公司。",
  "京东安联琥珀计划全球高端医疗险产品提供住院绿通服务、陪诊服务服务、二次诊疗服务、送药上门服务、视频医生服务、金牌医生服务、住院垫付服务、24小时健管团队服务，合作机构为中间带（北京）技术服务有限公司。",
  "京东安联明爱优选综合意外保障（广东尊享）产品提供在线图文咨询健康管理服务，合作机构为北京环球医疗救援有限责任公司。",

  "京东安联VIP医保旗舰版产品提供视频问诊及送药上门健康管理服务，合作机构为北京环球医疗救援有限责任公司；网络内医院直付服务（含绿通）、国内二诊、电话/在线咨询健康管理服务，合作机构为万欣和（上海）企业服务有限公司。",

  "京东安联住院宝保险（专业版）产品提供在线图文咨询、线下门诊陪诊健康管理服务，合作机构为北京环球医疗救援有限责任公司。",

  "京东安联臻爱无限医疗保险2021版产品提供在线图文咨询、药品折扣健康管理服务，合作机构为银川京东互联网医院有限公司；恶性肿瘤特药服务、100种重疾住院垫付服务、100种重疾住院绿通服务、在线健康咨询、国际二诊健康管理服务，合作机构为北京环球医疗救援有限责任公司。",

  "京东安联安享欣悦保险产品提供网络内医院直付服务（含绿通）、国内二诊、电话/在线咨询、直系亲属中国大陆境内重疾住院绿通、直系亲属全球重疾门诊绿通、被保险人心理咨询服务健康管理服务，合作机构为万欣和（上海）企业服务有限公司。",
  "京东安联-成长优享2023系列产品提供协助预约安排疫苗、儿童健康讲座、健康文章推送、公立医疗预约、儿科疾病线上问诊、送药上门健康管理服务，合作机构为上海风时健康技术有限公司。",

  "京东安联北京京航安海外务工保险产品提供心理咨询健康管理服务，合作机构为华美浩联医疗科技（北京）有限公司。",

  "京东安联北京京东世纪贸易有限公司保险产品提供网络内医院直付服务（含绿通）、国内二诊、电话/在线咨询健康管理服务，合作机构为万欣和（上海）企业服务有限公司。",

  "京东安联对华为员工境外旅行保险产品提供医疗救援服务；海外电话医疗咨询、医疗救援健康管理服务；合作机构为安联世合国际救援服务（北京）有限公司。",

  "京东安联京彩一生防癌医疗保险（互联网2023版）产品提供恶性肿瘤国内特药服务、恶性肿瘤住院垫付服务、恶性肿瘤线下门诊陪诊服务、恶性肿瘤住院绿通服务、恶性肿瘤住院垫付服务健康管理服务，合作机构为华美浩联医疗科技（北京）有限公司。",

  "京东安联住院宝保险（互联网2023版）产品提供在线图文健康咨询、线下门诊就诊陪诊健康管理服务，合作机构为北京环球医疗救援有限责任公司。",

  "京东安联臻爱无限医疗保险（互联网2023版）产品提供恶性肿瘤特药服务、100种重疾住院垫付服务、100种重疾住院绿通服务、国际二次诊疗、在线图文健康咨询健康管理服务，合作机构为北京启泰元康健康管理有限公司。",

  "京东安联臻爱无限医疗保险（互联网臻慧版）产品提供恶性肿瘤特药服务、100种重疾住院垫付服务、100种重疾住院绿通服务、100种重疾线下就医陪诊服务、国内MDT多学科会诊健康管理服务，合作机构为北京启泰元康健康管理有限公司。",

  "京东安联-安联康睿寰球产品提供第二诊疗意见、海外就医推荐、远程医疗服务（电话医生）服务，合作机构为易医健康管理咨询（上海）有限公司；提供网络内医院直付服务，合作机构为安联世合国际救援服务(北京)有限公司；提供药品直接付费服务，合作机构为北京百姓阳光大药房有限公司、上海华氏大药房有限公司；提供会员援助计划 (EAP)，合作机构为TELUS Health；提供旅行安全服务，合作机构为Crisis24 Group。",
];

export const data2 = [
  {
    id: "6",
    title:
      "京东安联财产保险有限公司2024年上半年个人短期健康保险业务整体综合赔付率为",
    count: "84.07%",
    date: "2024-07-30",
  },
  {
    id: "0",
    title:
      "京东安联财产保险有限公司2023年年度个人短期健康保险业务整体综合赔付率为",
    count: "37.55%",
    date: "2024-02-29",
  },
  {
    id: "1",
    title:
      "京东安联财产保险有限公司2023年上半年个人短期健康保险业务整体综合赔付率为",
    count: "30.63%",
    date: "2023-07-26",
  },
  {
    id: "1",
    title:
      "京东安联财产保险有限公司2022年年度个人短期健康保险业务整体综合赔付率为",
    count: "65.38%",
    date: "2023-02-09",
  },
  {
    id: "2",
    title:
      "京东安联财产保险有限公司2022年上半年个人短期健康保险业务整体综合赔付率为",
    count: "62.92%",
    date: "2022-07-31",
  },
  {
    id: "3",
    title:
      "京东安联财产保险有限公司2021年年度个人短期健康保险业务整体综合赔付率为",
    count: "34%",
    date: "2022-02-28",
  },
  {
    id: "4",
    title:
      "京东安联财产保险有限公司2021年上半年的个人短期健康保险业务整体综合赔付率为",
    count: "77.63%",
    date: "2021-07-31",
  },
  {
    id: "5",
    title:
      "京东安联财产保险有限公司2020年年度个人短期健康保险业务整体综合赔付率为",
    count: "60.7%",
    date: "2021-02-26",
  },
];
